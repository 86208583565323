import React, { useCallback, useState } from 'react'
import clsx from 'clsx'
import { Formik } from 'formik'
import { Auth } from 'aws-amplify'
import { Alert } from '@mui/material'
import { I18n } from 'react-redux-i18n'

import InputText from '../inputs/input-text'
import LoadingButton from '../loading-button'
import InputPassword from '../inputs/input-password'

import useResponsive from 'hooks/useResponsive'

import schema from './schema'

const ResetPasswordForm = ({ user }) => {
  const { isDesktop } = useResponsive()

  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const resendCode = useCallback(async () => {
    setLoading(true)

    try {
      await Auth.forgotPassword(user.username)
    } catch (err) {}

    setLoading(false)
  }, [user.username])

  const handleConfirmResetPassword = async ({ code, password }) => {
    try {
      await Auth.forgotPasswordSubmit(user.username, code, password)
    } catch (err) {
      console.error('err', err)
      const { code } = err
      let message

      switch (code) {
        case 'CodeMismatchException':
          message = I18n.t('CodeMismatchException')
          break
        case 'LimitExceededException':
          message = I18n.t('LimitExceededException')
          break
        case 'ExpiredCodeException':
          message = I18n.t('ExpiredCodeException')
          break
        default:
          message = I18n.t('App_default_error_message')
          break
      }

      setError(message)
    }
  }

  return (
    <Formik
      enableReinitialize
      validationSchema={schema}
      initialValues={{ code: '', password: '', confirm_password: '' }}
      onSubmit={handleConfirmResetPassword}
    >
      {({ values, errors, handleChange, handleSubmit, isSubmitting }) => (
        <div className='form'>
          <h1 className='mb-2 form-title'>{I18n.t(`Reset_Password_Title`)}</h1>

          <div
            className={clsx('content d-flex flex-column px-3', {
              'w-50': isDesktop(),
            })}
          >
            {error && (
              <Alert severity='error' onClose={() => setError(null)}>
                {error}
              </Alert>
            )}

            <InputText
              name='code'
              maxLength={6}
              value={values.code}
              onChange={handleChange}
              disabled={isSubmitting}
              error={Boolean(errors.code)}
              helperText={Boolean(errors.code) && errors.code}
              placeholder={I18n.t('Label_Code')}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault()
                }
              }}
            />

            <InputPassword
              name='password'
              value={values.password}
              onChange={handleChange}
              disabled={isSubmitting}
              error={Boolean(errors.password)}
              placeholder={I18n.t('Label_New_Password')}
              helperText={Boolean(errors.password) && errors.password}
            />

            <InputPassword
              name='confirm_password'
              onChange={handleChange}
              disabled={isSubmitting}
              value={values.confirm_password}
              error={Boolean(errors.confirm_password)}
              placeholder={I18n.t('Label_Confirm_Password')}
              helperText={
                Boolean(errors.confirm_password) && errors.confirm_password
              }
            />

            <div className='w-100 input d-flex flex-column justify-content-between mt-3'>
              <LoadingButton
                loading={isSubmitting}
                onClick={handleSubmit}
                className='w-100 primary mb-2'
                label={I18n.t('Button_Reset_Password')}
              />

              <LoadingButton
                loading={loading}
                onClick={resendCode}
                className='w-100 ghost prev'
                label={I18n.t('Button_Resend_Code')}
              />
            </div>
          </div>
        </div>
      )}
    </Formik>
  )
}

export default ResetPasswordForm
