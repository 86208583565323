import produce from 'immer'

const initialState = {
  auth: false,
  user: {
    id: '',
    username: '',
    attributes: {},
  },
}

const reducer = (state, action) => produce(state, (draftState) => {
  switch (action.type) {
    case 'SET_AUTH':
      const { auth } = action.payload.auth

      draftState.auth = auth
      break
    case 'SET_USER':
      const { user } = action.payload

      if (!user) {
        draftState.user = initialState.user
        return
      }

      draftState.user = { ...draftState.user, ...user }
      break
    case 'SET_PASSWORD':
      const { password } = action.payload

      draftState.user = { ...draftState.user, password }
      break
    case 'SET_USER_EMAIL':
      const { email } = action.payload

      draftState.user.attributes.email = email
      break
    default:
      return draftState
  }
})

export default reducer
export { initialState }
