import React, { useState } from 'react'
import { Formik } from 'formik'
import { Auth } from 'aws-amplify'
import { Alert } from '@mui/material'
import { I18n, Translate } from 'react-redux-i18n'

import InputText from '../inputs/input-text'
import LoadingButton from '../loading-button'

import schema from './schema'

const ForgotPasswordForm = () => {
  const [error, setError] = useState(null)

  const handleConfirmSendLink = async ({ email }) => {
    try {
      await Auth.forgotPassword(email)
    } catch (err) {
      const { code } = err
      let message

      switch (code) {
        case 'UserNotFoundException':
          message = I18n.t('UserNotFoundException')
          break
        default:
          message = I18n.t('App_default_error_message')
          break
      }

      setError(message)
    }
  }

  return (
    <Formik
      enableReinitialize
      validationSchema={schema}
      initialValues={{ email: '' }}
      onSubmit={handleConfirmSendLink}
    >
      {({ values, errors, handleChange, handleSubmit, isSubmitting }) => (
        <div className='form'>
          <h1 className='mb-3 form-title'>{I18n.t('Forgot_Password_Title')}</h1>

          <div className='content d-flex flex-column align-items-center'>
            <Translate className='mb-2' value='Forgot_Password_Message' />

            {error && (
              <Alert severity='error' onClose={() => setError(null)}>
                {error}
              </Alert>
            )}

            <InputText
              name='email'
              value={values.email}
              onChange={handleChange}
              disabled={isSubmitting}
              error={Boolean(errors.email)}
              helperText={Boolean(errors.email) && errors.email}
              placeholder={I18n.t('Label_Email_or_Username')}
            />

            <LoadingButton
              className='mt-2'
              loading={isSubmitting}
              onClick={handleSubmit}
              wrapperClass='justify-content-center'
              label={I18n.t('Button_Forgot_Password')}
            />
          </div>
        </div>
      )}
    </Formik>
  )
}

export default ForgotPasswordForm
