import React from 'react'
import { I18n } from 'react-redux-i18n'

import Footer from 'components/footer'

const ErrorPage = () => {
  return (
    <>
      <div className='container text-center'>
        <div className='mb-4'>
          <h1 className='display-1'>404</h1>

          <h3 className='mb-1'>{I18n.t('Not_Found_Title')}</h3>

          <p>{I18n.t('Not_Found_Message')}</p>
        </div>
      </div>

      <Footer />
    </>
  )
}

export default ErrorPage
