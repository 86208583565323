import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@mui/styles'
import { green } from '@mui/material/colors'
import { CircularProgress } from '@mui/material'

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}))

const LoadingButton = ({
  label,
  loading,
  onClick,
  className,
  wrapperClass,
}) => {
  const classes = useStyles()

  return (
    <div className={clsx(classes.wrapper, wrapperClass)}>
      <button className={clsx(className)} onClick={onClick} disabled={loading}>
        <span>{label}</span>
      </button>

      {loading && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
    </div>
  )
}

export default LoadingButton
