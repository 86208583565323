import * as yup from 'yup'
import { I18n } from 'react-redux-i18n'

const schema = yup
  .object()
  .shape({
    email: yup.string().required(I18n.t('RequiredEmailOrCode')),
  })
  .required()
  .noUnknown()

export default schema
