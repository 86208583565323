import React, { useState } from 'react'
import {
  IconButton,
  FormControl,
  FormHelperText,
  InputAdornment,
} from '@mui/material'
import clsx from 'clsx'
import { Visibility, VisibilityOff } from '@mui/icons-material'

const PasswordField = ({ error, helperText, inputClass, ...rest }) => {
  const [show, showPassword] = useState(false)

  const handleClickShowPassword = () => {
    showPassword(!show)
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  return (
    <FormControl className={clsx({ error })}>
      <div className='position-relative'>
        <input
          className={inputClass}
          type={show ? 'text' : 'password'}
          {...rest}
        />

        <InputAdornment position='end' className='position-absolute'>
          <IconButton
            edge='end'
            aria-label='toggle password visibility'
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
          >
            {show ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </InputAdornment>
      </div>

      {helperText && <FormHelperText error>{helperText}</FormHelperText>}
    </FormControl>
  )
}

export default PasswordField
