export const getCookie = (name) => {
  let nameEQ = name + '='
  let ca = document.cookie.split(';')

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]

    while (c.charAt(0) === ' ') c = c.substring(1, c.length)

    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
  }

  return null
}

export const setCookie = (name, value, expiry) => {
  let expires = ''

  if (expiry) {
    let date = new Date(expiry * 1000)
    expires = '; expires=' + date.toUTCString()
  }

  document.cookie = name + '=' + (value || '') + expires + '; path=/'
}

export const eraseCookie = (name) => {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
}
