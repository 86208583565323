import * as React from 'react'
import { TextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

const InputDate = (props) => (
  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <DatePicker
      {...props}
      openTo='day'
      orientation='portrait'
      inputFormat='dd/MM/yyyy'
      views={['year', 'month', 'day']}
      renderInput={({ inputProps, ...rest }) => (
        <TextField
          {...rest}
          {...props}
          className='input-date'
          inputProps={{
            ...inputProps,
            placeholder: props.placeholder,
            readOnly: true,
          }}
        />
      )}
    />
  </LocalizationProvider>
)

export default InputDate
