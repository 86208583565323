import * as yup from 'yup'
import { I18n } from 'react-redux-i18n'

const schema = yup
  .object()
  .shape({
    code: yup.string().required(I18n.t('RequiredCode')),
    password: yup
      .string()
      .required(I18n.t('RequiredPassword'))
      .matches(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*]).{6,}$/,
        I18n.t('RequiredStrongPassword')
      ),
    confirm_password: yup
      .string()
      .required(I18n.t('RequiredPassword'))
      .oneOf([yup.ref('password'), null], I18n.t('RequiredMatchPassword')),
  })
  .required()
  .noUnknown()

export default schema
