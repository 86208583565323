import React, { useCallback, useState } from 'react'
import clsx from 'clsx'
import { Formik } from 'formik'
import { Auth } from 'aws-amplify'
import { Alert } from '@mui/material'
import { I18n, Translate } from 'react-redux-i18n'

import InputText from '../inputs/input-text'
import LoadingButton from '../loading-button'

import useResponsive from 'hooks/useResponsive'

import schema from './schema'

const ConfirmSignupForm = ({ user }) => {
  const { isDesktop } = useResponsive()

  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const resendCode = useCallback(async () => {
    setLoading(true)

    try {
      await Auth.resendSignUp(user.username)
    } catch (err) {}

    setLoading(false)
  }, [user.username])

  const handleConfirmSignup = async ({ code }) => {
    try {
      await Auth.confirmSignUp(user.username, code)
      await Auth.signIn(user.username, user.password)
    } catch (err) {
      const { code } = err
      let message

      switch (code) {
        case 'CodeMismatchException':
          message = I18n.t('CodeMismatchException')
          break
        case 'NotAuthorizedException':
          message = I18n.t('IncorrectPasswordException')
          break
        default:
          message = I18n.t('App_default_error_message')
          break
      }

      setError(message)
    }
  }

  return (
    <Formik
      enableReinitialize
      validationSchema={schema}
      initialValues={{ code: '' }}
      onSubmit={handleConfirmSignup}
    >
      {({ values, errors, handleChange, handleSubmit, isSubmitting }) => (
        <div className='form'>
          <h1 className='mb-2 form-title'>{I18n.t(`Confirm_Signup_Title`)}</h1>

          <div
            className={clsx('content d-flex flex-column px-3', {
              'w-50': isDesktop(),
            })}
          >
            <Translate
              className='message'
              value={
                user.attributes.from === 'register'
                  ? 'Confirm_Signup_MessageAlt'
                  : 'Confirm_Signup_Message'
              }
              email={user.attributes.destination}
            />

            {error && (
              <Alert severity='error' onClose={() => setError(null)}>
                {error}
              </Alert>
            )}

            <InputText
              name='code'
              maxLength={6}
              value={values.code}
              onChange={handleChange}
              disabled={isSubmitting}
              error={Boolean(errors.code)}
              helperText={Boolean(errors.code) && errors.code}
              placeholder={I18n.t('Label_Confirm_Signup_Code')}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault()
                }
              }}
            />

            <div className='w-100 input d-flex flex-column justify-content-between mt-3'>
              <LoadingButton
                loading={isSubmitting}
                onClick={handleSubmit}
                className='w-100 primary mb-2'
                label={I18n.t('Button_Confirm')}
              />

              <LoadingButton
                loading={loading}
                onClick={resendCode}
                className='w-100 ghost prev'
                label={I18n.t('Button_Resend_Code')}
              />
            </div>
          </div>
        </div>
      )}
    </Formik>
  )
}

export default ConfirmSignupForm
