import React, { useMemo } from 'react'
import Lottie from 'react-lottie'
import { I18n } from 'react-redux-i18n'

import useResponsive from 'hooks/useResponsive'

import animationData from 'theme/assets/img/success.json'

const DesktopWrapper = ({ children }) => (
  <div className='wrapper d-flex align-items-center'>{children}</div>
)

const MobileWrapper = ({ children }) => (
  <div className='w-100 h-100'>
    <div className='mobile-login w-100 h-100 d-flex align-items-center'>
      <div className='login'>{children}</div>
      <div className='signup slide-up'></div>
    </div>
  </div>
)

const SuccessLoginContainer = () => {
  const { isDesktop } = useResponsive()

  const Wrapper = useMemo(
    () => (isDesktop() ? DesktopWrapper : MobileWrapper),
    [isDesktop]
  )

  return (
    <Wrapper>
      <div className='container text-center'>
        <div className='mb-4'>
          <Lottie
            width={200}
            height={200}
            options={{
              loop: false,
              autoplay: true,
              animationData: animationData,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
              },
            }}
          />

          <h3 className='mt-2 mb-1'>{I18n.t('Wait_Redirection')}</h3>
        </div>
      </div>
    </Wrapper>
  )
}

export default SuccessLoginContainer
