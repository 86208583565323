import axios from 'axios'
import jwt_decode from 'jwt-decode'

import { setCookie } from './cookie-helper'

export const storeTokens = async (
  authorization_code,
  idToken,
  accessToken,
  refreshToken
) => {
  /*
   * Call storage endpoint to store tokens in DynamoDB
   */
  const response = await axios.post(
    '/storage',
    {
      authorization_code: authorization_code,
      id_token: idToken,
      access_token: accessToken,
      refresh_token: refreshToken,
    },
    { headers: { 'Content-Type': 'application/json' } }
  )

  return response
}

export const setTokenCookie = (type, token) => {
  let tokenDecoded = jwt_decode(token)
  let tokenExpiry = tokenDecoded['exp']

  setCookie(type, token, tokenExpiry)
}

export const setRefreshTokenCookie = (refreshToken, accessToken) => {
  // Use expiry of access token to set the refresh token cookie
  let tokenDecoded = jwt_decode(accessToken)
  let tokenExpiry = tokenDecoded['exp']

  setCookie('refresh_token', refreshToken, tokenExpiry)
}
