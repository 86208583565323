import moment from 'moment'
import * as yup from 'yup'
import { I18n } from 'react-redux-i18n'

import { RESERVED_USERNAMES } from './data'

const step1Schema = yup
  .object()
  .shape({
    email: yup
      .string()
      .email(I18n.t('RequiredEmail'))
      .required(I18n.t('RequiredEmail')),
    username: yup
      .string()
      .min(4, I18n.t('RequiredUsernameTooShort'))
      .max(16, I18n.t('RequiredUsernameTooLong'))
      .matches(/^[a-zA-Z0-9]+$/, I18n.t('RequiredUsernameMatch'))
      .test(
        'is-reserved-username',
        I18n.t('RequiredUsernameReserved'),
        async (value) => !RESERVED_USERNAMES.includes(value)
      )
      .required(I18n.t('RequiredUsername')),
  })
  .required()
  .noUnknown()

const step2Schema = yup
  .object()
  .shape({
    given_name: yup.string().required(I18n.t('RequiredGivenName')),
    family_name: yup.string().required(I18n.t('RequiredFamilyName')),
  })
  .required()
  .noUnknown()

const step3Schema = yup
  .object()
  .shape({
    password: yup
      .string()
      .required(I18n.t('RequiredPassword'))
      .matches(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*]).{6,}$/,
        I18n.t('RequiredStrongPassword')
      ),
  })
  .required()
  .noUnknown()

const step4Schema = yup
  .object()
  .shape({
    country: yup.string().required(I18n.t('RequiredCountry')),
    birthdate: yup
      .date(I18n.t('RequiredBirthday'))
      .max(moment().subtract(18, 'years'), I18n.t('RequiredBirthdayLegal'))
      .required(I18n.t('RequiredBirthday')),
    company: yup.string().nullable(),
  })
  .required()
  .noUnknown()

const schema = [step1Schema, step2Schema, step3Schema, step4Schema]

export default schema