import {
  setLocale,
  loadTranslations,
  syncTranslationWithStore,
} from 'react-redux-i18n'
import { Amplify } from 'aws-amplify'
import { getCookie } from 'react-use-cookie'

import translations from 'translations'

import awsconfig from '../aws-exports'

import { LANG_COOKIE_NAME } from './constants'

// eslint-disable-next-line import/no-anonymous-default-export
export default (store) => {
  // setup app language
  const prevLang = getCookie(LANG_COOKIE_NAME) || 'en'

  syncTranslationWithStore(store)
  store.dispatch(loadTranslations(translations))
  store.dispatch(setLocale(prevLang))

  // setup amplify
  let amplifyConfig = {
    ...awsconfig,
  }

  // If we have in parameter that means start a PKCE or Implict flow
  // We switch the clientId to submit the one from the client website
  let queryStringParams = new URLSearchParams(window.location.search)
  let clientId = queryStringParams.get('client_id')

  if (clientId) {
    // We save the client ID, our Amplify auth will be based on that one
    localStorage.setItem('client-id', clientId)
  } else {
    // If there is no client-Id in query, we set back the last one used for login
    // it may be undefined if we never logged in
    clientId = localStorage.getItem('client-id')
  }

  if (clientId) {
    // We configure the Amplify Auth component in the context of using a client website client-id
    // if no clientId is found (direct login not from a client) the web client id of the broker will be used as default
    amplifyConfig.aws_user_pools_web_client_id = clientId
  }

  Amplify.configure(amplifyConfig)
}
