import React from 'react'
import clsx from 'clsx'
import { FormControl, FormHelperText } from '@mui/material'

const TextField = ({ error, helperText, inputClass, ...rest }) => (
  <FormControl className={clsx({ error })}>
    <div className='position-relative'>
      <input className={inputClass} type='text' {...rest} />
    </div>

    {helperText && <FormHelperText error>{helperText}</FormHelperText>}
  </FormControl>
)

export default TextField
