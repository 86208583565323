import React, { useEffect, useReducer, useRef, useState } from 'react'
import { Auth, Hub } from 'aws-amplify'
import { AuthState } from '@aws-amplify/ui-components'

import Footer from 'components/footer'
import SusccessLoginContainer from 'components/success-login-container'

import Desktop from './desktop'
import Mobile from './mobile'

import useResponsive from 'hooks/useResponsive'

import checkForceAuth from 'utils/check-force-auth'
import handleIdpLogin from 'utils/handle-idp-login'
import handleAuthUIStateChange from 'utils/handle-auth-state-change'

import reducer, { initialState } from './state'

const LoginPage = () => {
  const signupRef = useRef()
  const [{ user }, dispatch] = useReducer(reducer, initialState)
  const { component: ResponsiveLogin } = useResponsive({
    mobile: Mobile,
    desktop: Desktop,
  })

  const [shown, setShown] = useState('login')
  const [authState, setAuthState] = useState()
  const [cognitoUser, setCognitoUser] = useState()
  const [isPasswordRecovery, setPasswordRecovery] = useState(false)

  handleIdpLogin.bind(this)

  useEffect(() => {
    checkForceAuth()

    Auth.currentAuthenticatedUser()
      .then((user) => {
        setAuthState(AuthState.SignedIn)
        dispatch({ type: 'SET_USER', payload: { user } })
      })
      .catch(() => setAuthState(AuthState.SignIn))

    return Hub.listen('auth', ({ payload: { event, data } }) => {
      const nextAuthState = event === 'signIn' ? AuthState.SignedIn : event

      // eslint-disable-next-line default-case
      switch (event) {
        case 'signIn':
          dispatch({ type: 'SET_AUTH', payload: { auth: true } })

          if (data && data.username) {
            const user = {
              username: data.username,
              attributes: data.attributes,
            }

            dispatch({ type: 'SET_USER', payload: { user } })
          }

          setAuthState(AuthState.SignedIn)
          break
        case 'signUp':
          if (data && data.user && data.codeDeliveryDetails) {
            const user = {
              id: data.userSub,
              username: data.user.username,
              attributes: {
                from: 'register',
                destination: data.codeDeliveryDetails.Destination,
              },
            }

            dispatch({ type: 'SET_USER', payload: { user } })
          }

          setAuthState(AuthState.ConfirmSignUp)
          break
        case 'signOut':
          setAuthState(AuthState.SignIn)
          break
        case 'signIn_failure':
          const { code } = data

          if (code === 'UserNotConfirmedException') {
            setAuthState(AuthState.ConfirmSignUp)
          }

          if (code === 'PasswordResetRequiredException') {
            setAuthState(AuthState.ResetPassword)
          }
          break
        case 'forgotPassword':
          if (data && data.username) {
            const user = {
              id: '',
              attributes: {},
              username: data.username,
            }

            dispatch({ type: 'SET_USER', payload: { user } })
            setAuthState(AuthState.ResetPassword)
          }
          break
        case 'forgotPasswordSubmit':
          setShown('login')
          setAuthState(AuthState.SignIn)
          break
        case 'NEW_PASSWORD_REQUIRED':
          setCognitoUser(data)
          setAuthState('COMPLETE_NEW_PASSWORD')
          break
      }

      handleAuthUIStateChange(nextAuthState)
    })
  }, [])

  // eslint-disable-next-line no-unused-vars
  const handleIdPLogin = (identity_provider) => {
    // Store redirect_uri/authorization_code in local storage to be used to later
    let queryStringParams = new URLSearchParams(window.location.search)

    let clientState = queryStringParams.get('state')
    let redirect_uri = queryStringParams.get('redirect_uri')
    let authorization_code = queryStringParams.get('authorization_code')

    if (redirect_uri) {
      localStorage.setItem(`client-redirect-uri`, redirect_uri)
    }

    if (authorization_code) {
      localStorage.setItem(`authorization_code`, authorization_code)
    }

    if (clientState) {
      localStorage.setItem(`client-state`, clientState)
    }

    Auth.federatedSignIn({ provider: identity_provider })
  }

  return (
    <>
      {authState === AuthState.SignedIn && user && user.username ? (
        <SusccessLoginContainer />
      ) : (
        <ResponsiveLogin
          user={user}
          shown={shown}
          dispatch={dispatch}
          setShown={setShown}
          signupRef={signupRef}
          authState={authState}
          cognitoUser={cognitoUser}
          isPasswordRecovery={isPasswordRecovery}
          setPasswordRecovery={setPasswordRecovery}
          resetSignUpForm={() => signupRef.current.reset()}
        />
      )}

      <Footer />
    </>
  )
}

export default LoginPage
