import { Auth } from 'aws-amplify'

import { eraseCookie } from './cookie-helper'

const checkForceAuth = async () => {
  const forceAuth =
    new URLSearchParams(window.location.search).get('forceAuth') || false

  if (forceAuth) {
    eraseCookie('id_token')
    eraseCookie('access_token')
    eraseCookie('refresh_token')

    localStorage.removeItem('client-id')

    await Auth.signOut()
  }
}

export default checkForceAuth
