import React, {
  forwardRef,
  useState,
  useCallback,
  useImperativeHandle,
} from 'react'
import clsx from 'clsx'
import moment from 'moment'
import { Formik } from 'formik'
import { Auth } from 'aws-amplify'
import { Alert } from '@mui/material'
import { I18n, Translate } from 'react-redux-i18n'

import InputDate from '../inputs/input-date'
import InputText from '../inputs/input-text'
import LoadingButton from '../loading-button'
import InputCountry from '../inputs/input-country'
import InputPassword from '../inputs/input-password'
import ForgotPasswordForm from '../forgot-password-form'

import useResponsive from 'hooks/useResponsive'

import schema from './schemas'

const MyForm = ({ setShown, signupRef, isPasswordRecovery, ...rest }) =>
  isPasswordRecovery ? (
    <ForgotPasswordForm {...rest} />
  ) : (
    <SignUpForm setShown={setShown} ref={signupRef} {...rest} />
  )

const Step1 = ({ step, error, nextStep, setError, handleClick, ...rest }) => (
  <div className={clsx('form step', { active: step === 1, out: step === 2 })}>
    <h1 className='mb-3 form-title' onClick={handleClick}>
      <span>or</span>
      {I18n.t(`Sign_Up_Step1_Title`)}
    </h1>

    <div className='content d-flex flex-column px-3'>
      {error && (
        <Alert severity='error' onClose={() => setError(null)}>
          {error}
        </Alert>
      )}

      <InputText
        name='username'
        inputClass='input'
        value={rest.values.username}
        onChange={rest.handleChange}
        disabled={rest.isSubmitting}
        error={Boolean(rest.errors.username)}
        placeholder={I18n.t('SignIn_Username_Label')}
        helperText={Boolean(rest.errors.username) && rest.errors.username}
      />

      <InputText
        name='email'
        inputClass='input'
        value={rest.values.email}
        onChange={rest.handleChange}
        disabled={rest.isSubmitting}
        error={Boolean(rest.errors.email)}
        placeholder={I18n.t('Label_Email')}
        helperText={Boolean(rest.errors.email) && rest.errors.email}
      />

      <button className='input primary mt-3' onClick={() => nextStep(rest)}>
        <Translate value='Button_Next' />
      </button>
    </div>
  </div>
)

const Step2 = ({ step, error, prevStep, nextStep, setError, ...rest }) => (
  <div
    className={clsx('form step', {
      active: step === 2,
      next: step === 1,
      out: step === 3,
    })}
  >
    <h1 className='mb-3 form-title'>{I18n.t(`Sign_Up_Step2_Title`)}</h1>

    <div className='content d-flex flex-column px-3'>
      {error && (
        <Alert severity='error' onClose={() => setError(null)}>
          {error}
        </Alert>
      )}

      <InputText
        name='given_name'
        inputClass='input'
        onChange={rest.handleChange}
        disabled={rest.isSubmitting}
        value={rest.values.given_name}
        error={Boolean(rest.errors.given_name)}
        placeholder={I18n.t('Label_First_Name')}
        helperText={Boolean(rest.errors.given_name) && rest.errors.given_name}
      />

      <InputText
        name='family_name'
        inputClass='input'
        onChange={rest.handleChange}
        disabled={rest.isSubmitting}
        value={rest.values.family_name}
        error={Boolean(rest.errors.family_name)}
        placeholder={I18n.t('Label_Last_Name')}
        helperText={Boolean(rest.errors.family_name) && rest.errors.family_name}
      />

      <div className='w-100 input d-flex justify-content-between mt-3'>
        <button className='ghost prev' onClick={() => prevStep(rest)}>
          <Translate value='Button_Prev' />
        </button>

        <button className='primary' onClick={() => nextStep(rest)}>
          <Translate value='Button_Next' />
        </button>
      </div>
    </div>
  </div>
)

const Step3 = ({ step, error, prevStep, nextStep, setError, ...rest }) => (
  <div
    className={clsx('form step', {
      active: step === 3,
      next: step === 2,
      out: step === 4,
    })}
  >
    <h1 className='mb-3 form-title'>{I18n.t(`Sign_Up_Step3_Title`)}</h1>

    <div className='content d-flex flex-column px-3'>
      <Translate className='message' value='Sign_Up_Step3_Message' />

      {error && (
        <Alert severity='error' onClose={() => setError(null)}>
          {error}
        </Alert>
      )}

      <InputPassword
        name='password'
        inputClass='input'
        value={rest.values.password}
        onChange={rest.handleChange}
        disabled={rest.isSubmitting}
        error={Boolean(rest.errors.password)}
        placeholder={I18n.t('SignIn_Password_Label')}
        helperText={Boolean(rest.errors.password) && rest.errors.password}
      />

      <Translate
        className='mb-1 message'
        value='Label_Agree_Terms'
        button={I18n.t('Button_Next')}
        link1={I18n.t('Label_Link1')}
        link2={I18n.t('Label_Link2')}
        dangerousHTML
      />

      <div className='w-100 input d-flex justify-content-between mt-3'>
        <button className='ghost prev' onClick={() => prevStep(rest)}>
          <Translate value='Button_Prev' />
        </button>

        <button className='primary' onClick={() => nextStep(rest)}>
          <Translate value='Button_Next' />
        </button>
      </div>
    </div>
  </div>
)

const Step4 = ({ step, error, prevStep, nextStep, setError, ...rest }) => (
  <div className={clsx('form step', { active: step === 4, next: step === 3 })}>
    <h1 className='mb-3 form-title'>{I18n.t(`Sign_Up_Step4_Title`)}</h1>

    <div className='content d-flex flex-column px-3'>
      <Translate className='message' value='Sign_Up_Step4_Message' />

      {error && (
        <Alert className='mb-2' severity='error' onClose={() => setError(null)}>
          {error}
        </Alert>
      )}

      <InputCountry
        displayEmpty
        name='country'
        className='input'
        value={rest.values.country}
        onChange={rest.handleChange}
        disabled={rest.isSubmitting}
        error={Boolean(rest.errors.country)}
        label={I18n.t('Label_Country')}
        helperText={Boolean(rest.errors.country) && rest.errors.country}
      />

      <InputDate
        name='birthdate'
        className='input'
        value={rest.values.birthdate}
        disabled={rest.isSubmitting}
        error={Boolean(rest.errors.birthdate)}
        placeholder={I18n.t('Label_Birthdate')}
        helperText={Boolean(rest.errors.birthdate) && rest.errors.birthdate}
        onChange={(newValue) => {
          rest.setFieldValue('birthdate', newValue || '')
        }}
      />

      <InputText
        name='company'
        inputClass='input'
        value={rest.values.company}
        onChange={rest.handleChange}
        disabled={rest.isSubmitting}
        placeholder={I18n.t('Label_Company')}
        error={Boolean(rest.errors.company)}
        helperText={Boolean(rest.errors.company) && rest.errors.company}
      />

      <div className='w-100 input d-flex justify-content-between mt-3'>
        <button className='ghost prev' onClick={() => prevStep(rest)}>
          <Translate value='Button_Prev' />
        </button>

        <LoadingButton
          className='primary'
          loading={rest.isSubmitting}
          label={I18n.t('Button_Sign_Up')}
          wrapperClass='justify-content-end'
          onClick={() => nextStep(rest)}
        />
      </div>
    </div>
  </div>
)

const SignUpForm = forwardRef(({ setShown, dispatch }, ref) => {
  const { isDesktop } = useResponsive()

  const [step, setStep] = useState(1)
  const [error, setError] = useState(null)

  useImperativeHandle(ref, () => ({
    reset: () => {
      setStep(1)
    },
  }))

  const handleClick = useCallback(
    (e) => {
      e.preventDefault()

      if (!isDesktop()) {
        setShown('register')
      }
    },
    [isDesktop, setShown]
  )

  const prevStep = async (rest) => {
    setError(null)
    setStep(Math.max(step - 1, 1))
    await rest.validateForm()
    rest.setTouched({})
  }

  const nextStep = async ({ isValid, ...rest }) => {
    await rest.submitForm()

    if (isValid) {
      setStep((prev) => Math.min(prev + 1, 4))
      await rest.validateForm()

      rest.setTouched({})
    }
  }

  const handleSignup = async (data) => {
    if (step !== 4) return
    setError(null)

    const {
      email,
      company,
      country,
      password,
      username,
      birthdate,
      given_name,
      family_name,
    } = data

    const parsedBirthDay = moment(birthdate).format('DD/MM/YYYY')

    const payload = {
      username,
      password,
      attributes: {
        email,
        given_name,
        family_name,
        birthdate: parsedBirthDay,
        'custom:country': country,
      },
    }

    if (company) {
      payload.attributes['custom:company'] = `Client:${company}`
    }

    try {
      dispatch({ type: 'SET_PASSWORD', payload: { password } })
      await Auth.signUp(payload)
    } catch (error) {
      const { code } = error
      let message

      switch (code) {
        case 'UserNotFoundException':
          message = I18n.t('UserNotFoundException')
          break
        case 'UsernameExistsException':
          message = I18n.t('UsernameExistsException')
          break
        default:
          message = I18n.t('App_default_error_message')
          break
      }

      setError(message)
    }
  }

  return (
    <Formik
      enableReinitialize
      onSubmit={handleSignup}
      validationSchema={schema[step - 1]}
      initialValues={{
        email: '',
        company: '',
        country: '',
        password: '',
        username: '',
        birthdate: '',
        given_name: '',
        family_name: '',
      }}
    >
      {(props) => (
        <>
          <Step1
            step={step}
            error={error}
            nextStep={nextStep}
            setError={setError}
            handleClick={handleClick}
            {...props}
          />

          <Step2
            step={step}
            error={error}
            prevStep={prevStep}
            nextStep={nextStep}
            setError={setError}
            {...props}
          />

          <Step3
            step={step}
            error={error}
            prevStep={prevStep}
            nextStep={nextStep}
            setError={setError}
            {...props}
          />

          <Step4
            step={step}
            error={error}
            prevStep={prevStep}
            nextStep={nextStep}
            setError={setError}
            {...props}
          />
        </>
      )}
    </Formik>
  )
})

export default MyForm
