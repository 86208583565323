/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useState } from 'react'
import { Formik } from 'formik'
import { isEmpty } from 'lodash'
import { Alert } from '@mui/material'
import { I18n } from 'react-redux-i18n'
import { Auth, Hub } from 'aws-amplify'

import InputText from '../inputs/input-text'
import LoadingButton from '../loading-button'
import InputPassword from '../inputs/input-password'

import useResponsive from 'hooks/useResponsive'

const SignInForm = ({
  dispatch,
  setShown,
  setPasswordRecovery,
  resetSignUpForm,
}) => {
  const { isDesktop } = useResponsive()

  const [error, setError] = useState(null)

  const handleClick = useCallback(
    (e) => {
      e.preventDefault()

      if (!isDesktop()) {
        setShown('login')
        setPasswordRecovery(false)
        resetSignUpForm()
      }
    },
    [isDesktop, resetSignUpForm, setPasswordRecovery, setShown]
  )

  const validate = (values) => {
    setError(null)
    const errors = {}

    if (isEmpty(values.username)) {
      errors.username = I18n.t('Schemas_Login_required_Username')
    }

    if (isEmpty(values.password)) {
      errors.password = I18n.t('Schemas_Login_required_Password')
    }

    return errors
  }

  const handleLogin = async ({ username, password }) => {
    setError(null)

    try {
      const user = await Auth.signIn(username, password)

      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        Hub.dispatch('auth', {
          event: 'NEW_PASSWORD_REQUIRED',
          message: 'key',
          data: user,
        })
      }
    } catch (error) {
      const { code } = error
      let message

      switch (code) {
        case 'UserNotFoundException':
          message = I18n.t('UserNotFoundException')
          break
        case 'UserNotConfirmedException':
        case 'PasswordResetRequiredException':
          const user = {
            id: '',
            username,
            password,
            attributes: {},
          }

          dispatch({ type: 'SET_USER', payload: { user } })
          break
        case 'NotAuthorizedException':
          message = I18n.t('IncorrectPasswordException')
          break
        default:
          message = I18n.t('App_default_error_message')
          break
      }

      setError(message)
    }
  }

  return (
    <Formik
      enableReinitialize
      validate={validate}
      onSubmit={handleLogin}
      initialValues={{ username: '', password: '' }}
    >
      {({ values, errors, handleChange, handleSubmit, isSubmitting }) => (
        <div className='form'>
          <h1 className='mb-3 form-title' onClick={handleClick}>
            <span>or</span>
            {I18n.t('Button_Sign_In')}
          </h1>

          <div className='content d-flex flex-column'>
            {error && (
              <Alert severity='error' onClose={() => setError(null)}>
                {error}
              </Alert>
            )}

            <InputText
              name='username'
              value={values.username}
              onChange={handleChange}
              disabled={isSubmitting}
              error={Boolean(errors.username)}
              placeholder={I18n.t('SignIn_Username_Label')}
              helperText={Boolean(errors.username) && errors.username}
            />

            <InputPassword
              name='password'
              value={values.password}
              onChange={handleChange}
              disabled={isSubmitting}
              error={Boolean(errors.password)}
              placeholder={I18n.t('SignIn_Password_Label')}
              helperText={Boolean(errors.password) && errors.password}
            />

            <a
              href='#'
              className='forgot'
              onClick={(e) => {
                e.preventDefault()
                setPasswordRecovery(true)
                setShown('register')
              }}
            >
              {I18n.t('Button_Go_To_Recovery_Password')}
            </a>

            <LoadingButton
              className='w-100'
              loading={isSubmitting}
              onClick={handleSubmit}
              label={I18n.t('Button_Sign_In')}
            />
          </div>
        </div>
      )}
    </Formik>
  )
}

export default SignInForm
