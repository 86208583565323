import { useCallback, useMemo } from 'react'
import { useWindowSize } from 'react-use'

const useResponsive = ({ desktop, mobile, tablet } = {}) => {
  const { width } = useWindowSize()

  const isMobile = useCallback(() => width < 425, [width])

  const isTablet = useCallback(() => width < 768 && width >= 425, [width])

  const isDesktop = useCallback(() => width > 768, [width])

  const component = useMemo(() => {
    if (isDesktop()) {
      return desktop
    } else if (isTablet() && tablet != null) {
      return tablet
    } else {
      return mobile
    }
  }, [isDesktop, isTablet, desktop, mobile, tablet])

  return { component, isTablet, isMobile, isDesktop }
}

export default useResponsive
