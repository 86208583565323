import React, { useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'

// Theme
import Theme from 'theme'

// Componentes

// Pages
import LoginPage from 'pages/login'
import LogoutPage from 'pages/logout'
import ErrorPage from 'pages/error404'

import useResponsive from 'hooks/useResponsive'

const App = () => {
  const { isDesktop } = useResponsive()

  useEffect(() => {
    if (isDesktop()) {
      document.body.classList.remove('mobile')
    } else {
      document.body.classList.add('mobile')
    }
  }, [isDesktop])

  return (
    <Theme>
      <Routes>
        <Route path='/logout' element={<LogoutPage />} />
        <Route path='/oauth2/authorize' element={<LoginPage />} />
        <Route path='*' element={<ErrorPage />} />
      </Routes>
    </Theme>
  )
}

export default App
