import React, { useState } from 'react'
import clsx from 'clsx'
import { Formik } from 'formik'
import { Auth } from 'aws-amplify'
import { Alert } from '@mui/material'
import { I18n } from 'react-redux-i18n'

import InputText from '../inputs/input-text'
import LoadingButton from '../loading-button'
import InputPassword from '../inputs/input-password'

import useResponsive from 'hooks/useResponsive'

import schema from './schema'

const ResetPasswordForm = ({ cognitoUser }) => {
  const { isDesktop } = useResponsive()

  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const handleConfirmNewPassword = async (data) => {
    const { given_name, family_name, password } = data

    setLoading(true)

    try {
      await Auth.completeNewPassword(cognitoUser, password, {
        given_name,
        family_name,
      })
    } catch (err) {
      const { code } = err
      let message

      switch (code) {
        case 'CodeMismatchException':
          message = I18n.t('CodeMismatchException')
          break
        case 'LimitExceededException':
          message = I18n.t('LimitExceededException')
          break
        case 'ExpiredCodeException':
          message = I18n.t('ExpiredCodeException')
          break
        default:
          message = I18n.t('App_default_error_message')
          break
      }

      setError(message)
    }

    setLoading(false)
  }

  return (
    <Formik
      enableReinitialize
      validationSchema={schema}
      onSubmit={handleConfirmNewPassword}
      initialValues={{
        password: '',
        given_name: '',
        family_name: '',
        confirm_password: '',
      }}
    >
      {({ values, errors, handleChange, handleSubmit, isSubmitting }) => (
        <div className='form'>
          <h1 className='mb-2 form-title'>{I18n.t(`Reset_Password_Title`)}</h1>

          <div
            className={clsx('content d-flex flex-column px-3', {
              'w-50': isDesktop(),
            })}
          >
            {error && (
              <Alert severity='error' onClose={() => setError(null)}>
                {error}
              </Alert>
            )}

            <InputPassword
              name='password'
              value={values.password}
              onChange={handleChange}
              disabled={isSubmitting}
              error={Boolean(errors.password)}
              placeholder={I18n.t('Label_New_Password')}
              helperText={Boolean(errors.password) && errors.password}
            />

            <InputText
              name='given_name'
              inputClass='input'
              onChange={handleChange}
              disabled={isSubmitting}
              value={values.given_name}
              error={Boolean(errors.given_name)}
              placeholder={I18n.t('Label_First_Name')}
              helperText={Boolean(errors.given_name) && errors.given_name}
            />

            <InputText
              name='family_name'
              inputClass='input'
              onChange={handleChange}
              disabled={isSubmitting}
              value={values.family_name}
              error={Boolean(errors.family_name)}
              placeholder={I18n.t('Label_Last_Name')}
              helperText={Boolean(errors.family_name) && errors.family_name}
            />

            <div className='w-100 input d-flex flex-column justify-content-between mt-3'>
              <LoadingButton
                loading={loading}
                onClick={handleSubmit}
                className='w-100 primary mb-2'
                label={I18n.t('Button_Reset_Password')}
              />
            </div>
          </div>
        </div>
      )}
    </Formik>
  )
}

export default ResetPasswordForm
