import React from 'react'
import clsx from 'clsx'
import { AuthState } from '@aws-amplify/ui-components'

import Overlay from 'components/overlay'
import SignInForm from 'components/sign-in-form'
import SignUpForm from 'components/sign-up-form'
import NewPasswordForm from 'components/new-password-form'
import ConfirmSignupForm from 'components/confirm-signup-form'
import ResetPasswordForm from 'components/reset-password-form'

const Desktop = ({
  shown,
  setShown,
  authState,
  isPasswordRecovery,
  setPasswordRecovery,
  ...rest
}) => {
  return (
    <div className={clsx('wrapper', { active: shown === 'register' })}>
      {authState === 'COMPLETE_NEW_PASSWORD' && (
        <div className='form-wrapper w-100'>
          <NewPasswordForm {...rest} />
        </div>
      )}

      {authState === AuthState.ConfirmSignUp && (
        <div className='form-wrapper w-100'>
          <ConfirmSignupForm {...rest} />
        </div>
      )}

      {authState === AuthState.ResetPassword && (
        <div className='form-wrapper w-100'>
          <ResetPasswordForm {...rest} />
        </div>
      )}

      {(authState === AuthState.SignIn || authState === AuthState.SignUp) && (
        <>
          <div className='form-wrapper sign-up-wrapper wizard'>
            <SignUpForm isPasswordRecovery={isPasswordRecovery} {...rest} />
          </div>

          <div className='form-wrapper sign-in-wrapper'>
            <SignInForm
              setShown={setShown}
              setPasswordRecovery={setPasswordRecovery}
              {...rest}
            />
          </div>

          <Overlay
            setShown={setShown}
            setPasswordRecovery={setPasswordRecovery}
          />
        </>
      )}
    </div>
  )
}

export default Desktop
