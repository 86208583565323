import React from 'react'
import { Translate } from 'react-redux-i18n'

const Footer = () => (
  <footer className=''>
    <div className='container'>
      <div className='row align-items-md-center py-2'>
        <div className='col-md mb-3 mb-md-0'>
          <ul className='list-inline list-px-2 mb-0 float-end'>
            <li className='list-inline-item mx-2'>
              <a
                target='_blank'
                className='link'
                href='https://soveluss.com'
                rel='noopener noreferrer'
              >
                <Translate value='Label_Privacy_and_Policy' />
              </a>
            </li>
            <li className='list-inline-item mx-2'>
              <a
                target='_blank'
                className='link'
                href='https://soveluss.com'
                rel='noopener noreferrer'
              >
                <Translate value='Label_Terms_of_use' />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
