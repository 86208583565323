import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React from 'react'
import { Provider } from 'react-redux'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import bootstrap from 'config/bootstrap'

import App from 'app'
import store from 'redux/configureStore'
import reportWebVitals from 'utils/reportWebVitals'
import * as serviceWorker from 'utils/serviceWorker'

const { dispatch } = store

bootstrap(store)

const root = createRoot(document.getElementById('app'))

root.render(
  <Provider store={store}>
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <App />
    </BrowserRouter>
  </Provider>
)

export { dispatch }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
