import { combineReducers } from 'redux'
import { i18nReducer } from 'react-redux-i18n'

import AppReducer from './app.reducer'

const rootReducer = (injectedReducers) =>
  combineReducers({
    app: AppReducer,
    i18n: i18nReducer,
    ...injectedReducers,
  })

export default rootReducer