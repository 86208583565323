import { Auth } from 'aws-amplify'

const handleIdpLogin = async (identity_provider) => {
  const queryStringParams = new URLSearchParams(window.location.search)

  const clientState = queryStringParams.get('state')
  const redirect_uri = queryStringParams.get('redirect_uri')
  const authorization_code = queryStringParams.get('authorization_code')

  if (redirect_uri) {
    localStorage.setItem(`client-redirect-uri`, redirect_uri)
  }

  if (authorization_code) {
    localStorage.setItem(`authorization_code`, authorization_code)
  }

  if (clientState) {
    localStorage.setItem(`client-state`, clientState)
  }

  await Auth.federatedSignIn({ provider: identity_provider })
}

export default handleIdpLogin
