import React from 'react'
import { I18n, Translate } from 'react-redux-i18n'

const Overlay = ({ setShown, setPasswordRecovery }) => (
  <div className='overlay-wrapper'>
    <div className='overlay'>
      <div className='overlay-panel overlay-left'>
        <h1>{I18n.t('Sign_In_hero_Title')}</h1>

        <p>{I18n.t('Sign_In_hero_Message')}</p>

        <button className='ghost' onClick={() => setShown('login')}>
          <Translate value='Button_Sign_In' />
        </button>
      </div>

      <div className='overlay-panel overlay-right'>
        <h1>{I18n.t('Sign_Up_hero_Title')}</h1>

        <p>{I18n.t('Sign_Up_hero_Message')}</p>

        <button
          className='ghost'
          onClick={() => {
            setShown('register')
            setPasswordRecovery(false)
          }}
        >
          <Translate value='Button_Sign_Up' />
        </button>
      </div>
    </div>
  </div>
)

export default Overlay
