import React from 'react'
import clsx from 'clsx'
import { AuthState } from '@aws-amplify/ui-components'

import SignInForm from 'components/sign-in-form'
import SignUpForm from 'components/sign-up-form'
import NewPasswordForm from 'components/new-password-form'
import ConfirmSignupForm from 'components/confirm-signup-form'
import ResetPasswordForm from 'components/reset-password-form'

const Mobile = ({
  shown,
  setShown,
  authState,
  isPasswordRecovery,
  setPasswordRecovery,
  ...rest
}) => {
  return (
    <div className='w-100 h-100'>
      <div className='mobile-login w-100 h-100'>
        {authState === 'COMPLETE_NEW_PASSWORD' && (
          <div className='signup'>
            <NewPasswordForm {...rest} />
          </div>
        )}

        {authState === AuthState.ConfirmSignUp && (
          <div className='signup'>
            <ConfirmSignupForm {...rest} />
          </div>
        )}

        {authState === AuthState.ResetPassword && (
          <div className='signup'>
            <ResetPasswordForm {...rest} />
          </div>
        )}

        {(authState === AuthState.SignIn || authState === AuthState.SignUp) && (
          <>
            <div
              className={clsx('signup wizard', {
                'slide-up': shown === 'login',
              })}
            >
              <SignUpForm
                setShown={setShown}
                isPasswordRecovery={isPasswordRecovery}
                {...rest}
              />
            </div>

            <div
              className={clsx('login', { 'slide-up': shown === 'register' })}
            >
              <SignInForm
                setShown={setShown}
                setPasswordRecovery={setPasswordRecovery}
                {...rest}
              />
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default Mobile
